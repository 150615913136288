import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <section className="footer-section">
            <div></div>
            <p>Made by Tim Stark</p>
            <p></p>
            {/* <h4>(images from Unsplash and Adidas)</h4> */}
        </section>
    )
}
