import React from 'react'
import './About.css';

export default function About() {
    return (
        <div className="about-section">
            <h4>About This Site</h4>
            <div className='line'></div> 
            <div className="container-wrapper">
                <p></p>
            </div>
        </div>
    )
}
